
<template>
  <!-- 会员分销体系 -->
  <div>
    <div class="heading">
      <h3>会员分销体系</h3>
      <p>助力搭建B2B数字化创新平台，赋能B端业务场景，助力渠道模式升级</p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <div class="highlights">
        <h6>微心动会员分销体系亮点</h6>
        <p>全域流量覆盖</p>
        <div class="type">
          <div class="type_c" v-for="(v, index) in typelist" :key="index">
            <img :src="v.imgurl" />
            <p>{{ v.name }}</p>
          </div>
        </div>
        <div class="highlights_c">
          <div v-for="(v, index) in highlights" :key="index">
            <h4>
              <span>{{ index + 1 }}</span>
              {{ v.title }}
            </h4>
            <p>
              {{ v.content }}
            </p>
          </div>
        </div>
      </div>
      <div class="core">
        <h4>运营端功能</h4>
        <img src="../../assets/images/corelist.png" class="core_c" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      typelist: [
        {
          name: "小程序",
          imgurl: require("../../assets/images/wx.png"),
        },
        {
          name: "PC端",
          imgurl: require("../../assets/images/pc.png"),
        },
        {
          name: "H5端",
          imgurl: require("../../assets/images/h5.png"),
        },
        {
          name: "Android",
          imgurl: require("../../assets/images/an.png"),
        },
        {
          name: "iOS",
          imgurl: require("../../assets/images/ios.png"),
        },
      ],
      highlights: [
        {
          title: "搭建会员分销体系",
          content:
            "支持全民营销、员工分销和多级分销业务，帮助品牌商/平台触达终端、助力拓展互联网下新销售渠道， 提升销售业绩，实现经销体系的全链路数字化。",
        },
        {
          title: "统一用户管理",
          content:
            "统一的用户和数据体系，在各终端登录进行统一的账号识别，保证用户在各终端操作的一致性和数据的统一性。",
        },
        {
          title: "多维度商品展示",
          content:
            "首页展示自定义的轮播图、广告图、icon、推荐商品，可快速进入相应页面； 分类页按照前台分类展示，可按分类查看商品； 可按照关键词进行商品搜索，支持模糊搜索和分词搜索， 支持排序和筛选，快速定位商品。",
        },
        {
          title: "多种营销优惠",
          content:
            "支持订单和商品维度丰富的促销策略，可对优惠促销活动进行生命周期的管理。满减、 满赠、满折、满减券、折扣券、代金券等多种促 销活动，全方位满足运营需要。",
        },
      ],
      corelist: [
        "商品中心：统一的商品分类/价格/详情/营销比例管理，全方位展示商品信息；对接行业管理系统。",
        "会员中心：统一管理会员信息，智能分析会员行为偏好。",
        "交易中心：统一管理交易相关信息，支持订单和退单的查询及操作。",
        "营销中心：提供满减、满赠、满折、满减券、折扣券、代金券等促销活动，支持多种促销模式。满足企业不同的业务场景，帮助实现拉新、激活、转化等一系列运营策略。",
        "内容中心：可根据运营需要，配置首页的轮播图、广告图、主推商品等，自定义内容和跳转链接，帮助企业展示品牌形象、实现运营目标。",
        "经营数据：查看实时销售概况，销售数据对比，掌握销售概况，及时调整运营策略。",
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/Banner_2.png);
  background-size: 100% 100%;
  text-align: center;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    font-size: 0.2rem;
    line-height: 0.8rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}

.content {
  .highlights {
    padding: 1.36rem 0;
    text-align: center;
    h6 {
      font-size: 0.28rem;
      padding-bottom: 0.2rem;
    }
    p {
      font-size: 0.18rem;
      // line-height: 0.5rem;
      color: #666;
    }
    .type {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 4.2rem;
      .type_c {
        width: 1.99rem;
        height: 1.88rem;
        box-sizing: border-box;
        padding: 0.18rem 0;
        background-color: #f9faff;
        border-radius: 0.05rem;
        img {
          width: 0.64rem;
          height: 0.64rem;
        }
        p {
          font-size: 0.22rem;
          color: #333;
          line-height: 0.26rem;
          font-weight: 550;
        }
      }
    }
    .highlights_c {
      width: 11.88rem;
      height: 4.93rem;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0.54rem 0.89rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-image: url(../../assets/images/highlights_c.png);
      background-size: 100% 100%;
      div {
        width: 4rem;
        text-align: left;
        padding: 0.43rem 0.2rem 0;
        h4 {
          font-size: 0.22rem;
          padding-bottom: 0.14rem;
          span {
            display: inline-block;
            width: 0.32rem;
            line-height: 0.32rem;
            background-color: #ffc53d;
            color: #fff;
            border-radius: 0.05rem;
            text-align: center;
            margin-right: 0.08rem;
          }
        }
        p {
          color: #666;
          font-size: 0.16rem;
          line-height: 00.24rem;
        }
      }
    }
  }
  .core {
    padding: 0 1.78rem 0.63rem;
    h4 {
      text-align: center;
      font-size: 0.28rem;
      color: #333;
    }
    .core_c {
      padding-top: 0.48rem;
      width: 15.64rem;
      height: 3.66rem;
    }
  }
}
</style>
